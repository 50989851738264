 body{
 	padding-top: 100px;
 }
img{
	max-width: 100%;
}
 .headertp {
 	.navbar-light{
 		background: #fff !important;
 		box-shadow: 0 8px 6px -6px #eee;
 	}
 	.navbar-collapse{
 		flex-grow:0;
 	}
 	.navbar-expand-md .navbar-nav .nav-link{
 		padding-left: 15px;
 		padding-right: 15px;
 		color: #0a1f54;
 	}
 }

 .formWhy{
 	margin:35px 0 0 0;
 	background:#000 url(../images/bannerbg.jpg);
 	background-size:cover;
 	.formbx2{
	 	margin:0;
	 	background:rgba(0,0,0,0.5);
	 	background-size:cover;
	 	padding:60px 0 60px 0;
	 	.intro-form{
		 	padding:30px 40px 40px;
		 	border-radius:15px;
		 	background:#fff;
		 	/*webkit-box-shadow:0px 40px 80px -40px #000 !important;
		 	-webkit-box-shadow:0px 40px 80px -40px #000 !important;*/
		 	/*box-shadow:0px 40px 80px -40px #000 !important;*/
		 	h4{color:#343434;
			 	font-size:25px;
			 	margin:5px 0 20px;
			 	/*text-align:center;*/
			 	text-transform:uppercase;
			 	font-weight:700;
			 	line-height:33px;
			 }
		 }
	 }

 }
.cliNtBx{
	padding: 45px 0;
	.LogoC{
		margin: 25px 0;
		position: relative;
		height: 150px;
		img{
			position: absolute;
			max-width: 100%;
			width: auto;
			top: 0;
			bottom: 0;
			left: 0; right: 0;
			margin: auto;
		}
	}
	h2 {
   font-size:35px;
    text-align: center;
    color:#11224d;
    font-weight:400;
    font-family:$headingFont;
    strong{
 			font-weight:900;
 		}
}
h2::before,
h2::after {
	
    display: inline-block;
    content: "";
    border-top: .2rem solid black;
    width: 4rem;
    margin: 0 1rem;
    transform: translateY(-0.6rem);
}
}
.pBoxmain{
	
h2 {
   font-size:35px;
    text-align: center;
    color:#11224d;
    font-weight:400;
    font-family:$headingFont;
    strong{
 			font-weight:900;
 		}
}
h2::before,
h2::after {
	
    display: inline-block;
    content: "";
    border-top: .2rem solid black;
    width: 4rem;
    margin: 0 1rem;
    transform: translateY(-0.6rem);
}


	.ProdBox{
		text-align: center;
		padding: 25px 0;
		h4{
			font-family:$headingFont;
			font-size: 18px;
			padding-top: 10px;
			color: #0097ea;
		}
		img{
			max-width: 100%;
			box-shadow: #eee 0 0 5px;
			/*border: #ccc 1px solid;*/
			padding: 4px;
		}
	}
}

 .formWhy .formbx2 .intro-form .intro-box{margin-top:30px;}
 .formWhy .formbx2 .intro-form span{float:left;width:50px;color:#fff;font-size:50px;}
 .formWhy .formbx2 .intro-form i{position:absolute;left:23px;line-height:70px;top:0;}
/* .formWhy .formbx2 .intro-form div{margin-left:75px}*/
 .formWhy .formbx2 .btn{background:#0097ea;color:#fff;height:38px; margin-top: 15px;}
 .formWhy .formbx2 .btn:hover{border:#FF854D 1px dotted;background:none;color:#FF854D;}

 .formWhy .rightb{margin:0}
 .formWhy .rightb h2{
 	color:#fff;
 	font-size:33px;
 	font-family:$headingFont;
 	font-weight:700;
 }
 .formWhy .rightb .rightbox{margin:10px 0;}
 .formWhy .rightb .rightbox .iconbx{
 	margin: 10px 0;
 }
 .formWhy .rightb .rightbox h4{
 	color:#00a1ed;
 	font-size:17px;
 	text-transform:uppercase;
 	font-family:$headingFont;
 	font-weight: 700;
 }
 .formWhy .rightb .rightbox p{color:#fff;font-size:16px}


 .overbx{background:#262626 url(../images/2.jpg) no-repeat center fixed;background-size:cover}
 .overbx .overbx1{margin:0;background:rgba(0,0,0,0.5);background-size:cover;padding:35px 0}
 .overbx .overbx1 h4{color:#fff;font-size:28px;font-weight:700;font-family:"Comfortaa", cursive}
 .overbx .overbx1 .btn{background:#0097ea;color:#fff}
 .overbx .overbx1 .btn:hover{border:#FF854D 1px dotted;background:none;color:#FF854D}



 .abbx{background:#000 url(../images/pagebg.jpg) center;background-size:cover;padding:0px 0;margin-bottom:25px}
 .abbx .abbxbg{background:rgba(0,0,0,0.7);padding:85px 0;text-align:center}
 .abbx .abbxbg h1{margin:0;color:#fff;padding:0 0;font-family:"Comfortaa",cursive;font-size:36px;font-weight:800}
 .welBox{
 	padding: 35px 0;
 	h1{
 		color:#11224d;
 		font-size:35px;
 		font-family:$headingFont;
 		font-weight:400;
 		strong{
 			font-weight:900;
 		}
 	}
 	p{
 		color:#333;
 		font-family:$secondFontFamily;
 		font-size:18px;
 	a{
 		color:#000;
 	}
 	}
 	.btn{
 		background:#0097ea;
 		color:#000;
 		margin:15px 0;
 		&:hover{
 			border:#FF854D 1px dotted;
 			background:none;
 			color:#FF854D;
 		}
 	}
 }
.wtbox{
	background: #eee;
	padding: 45px 0;
	text-align: center;
	p{
		line-height: 28px;
	}
	 .btn{
	 	background:#0097ea;
	 	color:#fff;
	 	display: inline-block;
	 	&:hover{
	 		border:#FF854D 1px dotted;
	 		background:#fff;
	 		color:#FF854D;
	 	}
	 }
 
	h2 {
   font-size:35px;
    text-align: center;
    color:#11224d;
    font-weight:400;
    font-family:$headingFont;
    strong{
 			font-weight:900;
 		}
	}
	h2::before,
	h2::after {
		
	    display: inline-block;
	    content: "";
	    border-top: .2rem solid black;
	    width: 4rem;
	    margin: 0 1rem;
	    transform: translateY(-0.6rem);
	}
}

 .contBx{
 	margin:0;
 	background:#fff; 
 	padding:55px 0;
 	h2 {
   font-size:35px;
    text-align: center;
    color:#11224d;
    font-weight:400;
    font-family:$headingFont;
    strong{
 			font-weight:900;
 		}
	}
	h2::before,
	h2::after {
		
	    display: inline-block;
	    content: "";
	    border-top: .2rem solid black;
	    width: 4rem;
	    margin: 0 1rem;
	    transform: translateY(-0.6rem);
	}
 }
 .contBx p{color:#555;letter-spacing:0.5px}
 .contBx a{color:#555;letter-spacing:0.5px}


 .contBx h1{color:#343434;font-size:36px;font-weight:700;font-family:"Comfortaa", cursive;line-height:46px}
 .contBx .BoxCon{
 	margin:35px 0;
 	background:#fff;
 	padding:25px;
 	border-radius:15px;
 	box-shadow:0px 7px 16px 0px rgba(50,50,50,0.2);
 	text-align:center;
 	min-height:220px;
 }
 .contBh{
 	padding:0 0 25px 0;
 }
.contBx .BoxCon h3{color:#343434;font-weight:bold;font-size:18px;text-transform:capitalize;margin:15px 0;padding:0px}
 .contBx .BoxCon .iconbX1{text-align:center;margin:auto}
 .contBx .BoxCon .iconbX1 svg{font-size:35px;color:#0097ea;margin:14px 4px}
 .banntop{
 	top:60px !important;
 }
 .footerbg{
 	text-align: center;
 	.socbx{
 		margin:0;
 		padding-bottom: 25px;
 		a{
 			color:#000;
 			display:inline-block;
 			padding:10px 8px;
 		}
 		/* ul{
 		 	margin:0;
 		 	padding:0;
 		 	li{
 		 		display:block;
 		 		a{
 		 			padding:3px 0;
 		 			color:#999;
 		 			&:hover{
 		 				color:#FF854D;
 		 			}

 		 		}
 		 	}
 		 }*/
 	}
 }
 footer{
	/*background:#262626;*/
	padding:16px 0;
	color:#000;
	text-align:center;
}

 .PageBanner{
 	margin: 0;
 	height: 250px;
 	overflow: hidden;
 	margin: auto;
 	background: #000;
 	img{
 		width: 100%;
 		margin: auto;
 		text-align: center;
 		margin-top: -8%;
 		/*opacity: 4;*/
 	}
 }
.LiveBox{
	margin: 0 0 25px 0;
	.DemoBox{
		height: 250px;
		border: #eee 1px solid;
		margin-bottom: 15px;
		border-radius: 4px;
		position: relative;
		img{
			position: absolute;
			height: auto;
			max-width: 100%;
			height: 190px;
			left: 0;
			right: 0;
			margin: auto;
			top: 0;
			bottom: 0;
		}
	}
	h4{
		text-align: center;
		color: #007bff;
		font-size: 16px;
		font-family: $headingFont;
	}
}
.gallerbx{margin:10px 0; position:relative; text-align:center;}
.gallerbx img{width:auto; max-width:100%; height:180px; margin-bottom:15px;}
.gallerbx h4{font-size:16px; text-align:center; color:#666;}
.gallerbx1 img{width:100%; height:240px;}
.gallerbx1 h4{font-size:18px; color:#666;}
/* .galleryBx{padding:0px 0}
 .galleryBx .gallery{text-align:center;margin:10px 0}
 .galleryBx .gallery img{height:190px;max-width:100%}
 .galleryBx .gallery h4{text-align:center;color:#FF854D;font-size:1.2rem;padding:10px 0}*/


 .btnbxM{display:none;}


.bannerBx{
 .d-table {width: 100%;height: 100%;display: table;}

.d-tablecell {display: table-cell;vertical-align: middle;}
.custom-btn1 {
	background-color: #0097ea; color: #fff;
	border: 1px solid #0097ea;
	display: inline-block;padding: 12px 30px;
	text-transform: uppercase;border-radius: 30px;
	text-decoration: none;
}
.custom-btn1:hover {
	background-color: transparent;
	text-decoration: none;
	color: #fff;
}
/*
Hero slider style
==========================*/
.hero-slider {position: relative;}
.single-hs-item {
	height: 700px;
	background-size: cover;
	background-position: center center;
	position: relative;
}
.single-hs-item:before {content: '';
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
/*background-color: #000;
opacity: .6;*/
}
/*.item-bg1 {
background-image: url(https://techsolutionshere.com/wp-content/uploads/2019/01/s1.jpg);
}
.item-bg2 {
background-image: url(https://techsolutionshere.com/wp-content/uploads/2019/01/s2.jpg);
}
.item-bg3 {
background-image: url(https://techsolutionshere.com/wp-content/uploads/2019/01/s3.jpg);
}*/
.hero-text {padding: 0 15px;text-align: center;max-width: 1140px;margin-left: auto;margin-right: auto;position: relative;}
.hero-text h1 {color: #fff;font-size: 50px;text-transform: capitalize;font-weight: 600;margin: 0;}
.hero-text p {color: #fff;font-size: 17px;max-width: 600px;margin-left: auto;margin-right: auto;line-height: 30px;margin-top: 20px;margin-bottom: 35px;}
.hero-slider .owl-item.active h1 {-animation: 1s .3s fadeInUp both;}
.hero-slider .owl-item.active p {-webkit-animation: 1s .3s fadeInUp both;animation: 1s .3s fadeInUp both;}
.hero-slider .owl-item.active .slider-btn {-webkit-animation: 1s .3s fadeInUp both;animation: 1s .3s fadeInUp both;}
.owl-carousel .owl-nav button.owl-prev {background-color: #0097ea;;position: absolute;left: 0;top: 50%;
color: #fff;font-size: 30px;margin: -40px 0 0;border-radius: 0;height: 50px;width: 50px;}
.owl-carousel .owl-nav button.owl-next {background-color: #0097ea;position: absolute;right: 0;top: 50%;color: #fff;font-size: 30px;margin: -40px 0 0;border-radius: 0;height: 50px;width: 50px;
}
.owl-theme .owl-nav {margin-top: 0;}
.owl-dots {position: absolute;left: 0;right: 0; bottom: 20px;}
.owl-theme .owl-dots .owl-dot span {width: 11px;height: 20px;}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {background: #0097ea;}
}