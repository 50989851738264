 @media all and (max-width: 1024px) {
  .navbar-brand{width:75%;float:left}
  .PageBanner{
  height: 120px;
  img{
    margin-top: 0%;
    height: 100%;
  }
 }

}
@media only screen and (max-width: 600px) {
  .bannerBx{
    .single-hs-item {
        height: auto;
        img{
          height: 100%; 
          width: 100%;
        }
    }
    .hero-text h1 {
        font-size: 30px;
    }
    .hero-text p {
        font-size: 15px;
        margin-bottom: 25px;
    }
    .owl-carousel .owl-nav button.owl-next {
        top: 30%;
        margin: 0;
        bottom: 0px;
    }
    .owl-carousel .owl-nav button.owl-prev {
        top: 30%;
        margin: 0;
        bottom: 0px;
    }
    .owl-dots {
        bottom: 10px;
        left: 50px;
        right: 50px;
    }
  }
}