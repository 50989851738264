
$primaryColor: #000;
$secondryColor: #939393;
$bodyTextColor: #6e6e6e;
$black: #000;
$white: #fff;
$placeholderColor: #000;
$red: #ff0000;
$active: #28a7e1;
$hover: #0e6eb8;
$bodyFontFamily: 'Questrial', sans-serif;
$headingFont: 'Exo', sans-serif;
$secondFontFamily: 'Questrial', sans-serif;
$h1Size: 36px;
$h2Size: 24px;
$h3Size: 22px;
$h4Size: 18px;
